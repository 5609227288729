body {
    margin: 0;
    font-family: "Figtree", -apple-system, BlinkMacSystemFont, "Segoe UI", "Figtree", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    background: white;
    font-size: 24px;
    overflow: hidden; /* Prevent body from scrolling */
    display: flex;
    flex-direction: column;
}

.app {
    background: whitesmoke;
    display: flex;
    flex: 1;
    overflow: hidden; /* Prevent main content from scrolling */
}

h1 {
    color: #165a72;
    margin: 50px auto;
    font-size: 40px;
}

.menu1 {
    margin-bottom: 40px;
    margin-top: 10px;
}

h2 {
    color: #165a72;
}

.sidebar-container {
    width: 250px;
    background-color: #f8f9fa;
    overflow-y: auto; /* Allow sidebar to scroll */
    flex-shrink: 0;
    position: fixed; /* Fix sidebar position */
    height: 100vh; /* Full height for sidebar */
}

.menu-item {
    position: relative;
    margin-bottom: 10px;
}

.default-menu-item-text {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px;
}

.full-menu-item-text {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px;
    z-index: 1000;
    white-space: nowrap;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out, width 0.3s ease-in-out;
    min-width: 100px;
    max-width: calc(100vw - 40px);
    font-size: 14px;
    text-align: center;
    line-height: 1.5;
}

.menu-item:hover .full-menu-item-text {
    opacity: 1;
    pointer-events: auto;
    max-width: calc(100vw - 10px);
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .sidebar-container {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        z-index: 1000;
        background-color: #f8f9fa;
    }

    .menu-item {
        margin-bottom: 20px;
    }

    .default-menu-item-text {
        width: auto;
        font-size: 14px;
    }

    .full-menu-item-text {
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        max-width: calc(100vw - 20px);
    }
}
.menu-item .active-menu-item {
    background-color: #e5e5e5; /* Example background color for active menu item */
    color: #165a72; /* Example text color for active menu item */
    font-weight: bold; /* Example font weight for active menu item */
}
